import { createTheme } from '@material-ui/core';

export enum TerraColor {
  // Grays
  White = '#FFFFFF',
  Gray10 = '#F8FAFA',
  Gray20 = '#DEE6E6',
  Gray40 = '#B0C1C5',
  Gray60 = '#617575',
  Gray80 = '#3D4949',
  Gray100 = '#212829',

  // Teals
  Teal10 = '#D5F2F2',
  Teal80 = '#447476',
  Teal100 = '#1E3D3F',

  // Greens
  Green10 = '#E3F5D5',
  Green40 = '#619D54',
  Green80 = '#266119',

  // Yellows
  Yellow20 = '#FFEAA1',
  Yellow40 = '#FDB839',
  Yellow80 = '#474128',

  // Reds
  Red20 = '#FFDADA',
  Red40 = '#EF5B57',
  Red60 = '#B70505',

  // Secondary Colors: Should just be used for Brand Health
  // Purples
  Purple10 = '#E3CEFF',
  Purple40 = '#A076D7',
  Purple80 = '#401D6E',

  // Avocado
  Avocado10 = '#F9FFB6',
  Avocado40 = '#C9E858',
  Avocado80 = '#405606',

  // Orange
  Orange10 = '#FFD2B6',
  Orange40 = '#FD8439',
  Orange80 = '#893E15',

  // Accent colors
  Stone10 = '#F8F8F8',
  Stone80 = '#454545',
  Treetrunk10 = '#F6F2EC',
  Treetrunk80 = '#594B39',
  Reef10 = '#FFEDE8',
  Reef80 = '#A42D07',
  Ocean10 = '#DFE6F8',
  Ocean80 = '#2F406C',
  Forest10 = '#E3FAE0',
  Forest80 = '#31622A',
  Rose10 = '#FEE3F0',
  Rose80 = '#874965',
  Redrock10 = '#FFDCDC',
  Redrock80 = '#962A2A',
  Pollen10 = '#FFE7C1',
  Pollen80 = '#875A17',
  Lavender10 = '#E7E0EE',
  Lavender80 = '#755990',
}

export const getTheme = () => {
  const baseFont = { fontWeight: 400, letterSpacing: 'normal' };
  return createTheme({
    // Matched with marketplace media queries
    breakpoints: {
      values: {
        xs: 0,
        sm: 550,
        md: 768,
        lg: 1024,
        xl: 1921,
      },
    },
    palette: {
      primary: {
        light: TerraColor.Teal10,
        main: TerraColor.Teal80,
        dark: TerraColor.Teal100,
        contrastText: TerraColor.White,
      },
      secondary: {
        light: TerraColor.Gray10,
        main: TerraColor.Gray80,
        dark: TerraColor.Gray100,
        contrastText: TerraColor.White,
      },
      error: {
        light: TerraColor.Red20,
        main: TerraColor.Red40,
        dark: TerraColor.Red60,
      },
      warning: {
        light: TerraColor.Yellow20,
        main: TerraColor.Yellow40,
        contrastText: TerraColor.Yellow80,
      },
      success: {
        light: TerraColor.Green10,
        main: TerraColor.Green40,
        dark: TerraColor.Green80,
      },
    },
    typography: {
      fontFamily: 'TT Commons !important',
      h1: { ...baseFont, fontSize: '2rem' },
      h2: { ...baseFont, fontSize: '1.5rem' },
      h3: { ...baseFont, fontSize: '1rem' },
      h4: { ...baseFont, fontSize: '1rem' },
      h5: { ...baseFont, fontSize: '1rem' },
      h6: { ...baseFont, fontSize: '1rem' },
      subtitle1: { ...baseFont, fontSize: '1rem', textTransform: 'uppercase' },
      subtitle2: { ...baseFont, fontSize: '0.75rem', textTransform: 'uppercase' },
      body1: { ...baseFont, fontSize: '1rem' },
      body2: { ...baseFont, fontSize: '0.75rem' },
      button: {
        textTransform: 'none' as any,
      },
    },
  });
};
